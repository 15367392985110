.scrollbar {
  width: 20px;
  opacity: 0.5;  /* for demo purpose  */
  background-color: #dae0e5;
  position: absolute;
  border-radius: 10px;
  cursor: pointer;
  touch-action: none;
  right: 0; /* Ensure the scrollbar is aligned to the right */
  top: 0;
  bottom: 0;
  margin-right: 10px;
}

.scrollbar-container {
  width: 20px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 999;
  touch-action: none;
}

.image-container {
  position: relative;
  display: inline-block; /* Ensure the container wraps around the image and scrollbar */
}

.image-caption {
  margin: auto;
}

img {
  max-width: 100%;
  display: block; /* Ensure the image is a block element */
}