/* ul {
   list-style-position: inside;
}

p {
  vertical-align: middle;
}

span {
  vertical-align: middle;
  margin: 5px; 
} 

ul li li {
  margin-left: 20px;
}  */
table th {
  text-align: left !important;
}